<template>
    <div class="pb-5">
        <v-title title="Bulk Invoices"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Bulk Invoices</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listInvoicesByApplication')">
                <mercur-card class="mb-4 mt-n4">
                    <table-search-filter @change="applyFilter" :filterFields="filterFields" :dropdownFilters="dropdownFilters" :has-date-range="true"></table-search-filter>
                </mercur-card>

                <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                <merchant-data-table class="shadow" :options="options" :url="url" ref="table" @paginationSet="updatePagination"></merchant-data-table>
            </template>
            <span v-else>
                Not allowed to see this view
            </span>
        </div>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import TableSearchFilter from '@/components/elements/table/SearchFilter'
import Pagination from '@/components/elements/table/Pagination'
import Currency from '@/components/elements/Currency'

export default {
    name: 'BulkInvoicesView',
    components: {
        MerchantDataTable,
        TableSearchFilter,
        Pagination,
    },
    data () {
        return {
            url: CONFIG.API.ROUTES.INVOICES.BULK_OVERVIEW.replace('{agentId}', this.$store.state.auth.user.accountId),
            pagination: {},
            amountOfDisplayedItems: 50,
            filterFields: {
                'orderNumber': { 'label': 'OrderId' },
                'invoiceNumber': { 'label': 'Invoice Number' },
                'accountNumber': { 'label': 'Account Number' },
            },
            dropdownFilters: [
                {
                    column: 'status',
                    label: 'Invoice Status',
                    operator: 'equals',
                    values: {
                        'Sent': 'SENT',
                        'Paid': 'PAID',
                    },
                },
            ],
            options: {
                columns: {
                    'Invoice Number': { field: 'invoiceNumber', width: 220 },
                    'Account Number': {
                        field: 'accountNumber',
                        valueFormatter: params => params.data.accountNumber || '-',
                    },
                    'Order Id': {
                        field: 'orderNumber',
                        link: (value, data) => {
                            return {
                                name: 'OrderWrapper',
                                params: {
                                    applicationId: data.applicationId,
                                    accountId: data.accountId,
                                    orderId: data.orderId,
                                },
                            }
                        },
                    },
                    'Customer': {
                        field: ['billingInfo.address.firstName', 'billingInfo.address.lastName'],
                        link: (value, data) => {
                            return {
                                name: 'CustomerView',
                                params: {
                                    applicationId: data.applicationId,
                                    customerId: data.accountId,
                                },
                            }
                        },
                    },
                    'Shop': { field: 'applicationName' },
                    'Invoice Date': { field: 'dateCreated' },
                    'Due Date': { field: 'dateCreated' },
                    'Payment Method': { field: 'paymentMethod' },
                    'Invoice Status': { field: 'status', statusChip: true },
                    'Total': {
                        field: 'totals.total',
                        cellRendererFramework: Currency,
                        cellRendererParams: (params) => {
                            return {
                                value: params.value,
                                type: params.currency,
                            }
                        },
                    },
                    'Date From': {
                        field: 'dateFrom',
                        hide: true,
                    },
                    'Date To': {
                        field: 'dateTo',
                        hide: true,
                    },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Download PDF',
                            onClick: ({ data }) => {
                                this.downloadInvoice(data)
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
            },
        }
    },
    methods: {
        downloadInvoice (params) {
            const url = CONFIG.API.ROUTES.INVOICES.DOWNLOAD
                .replace('{objectName}', params.invoiceUri)

            this.addJob(url)
            this.$root.$emit('notification:global', {
                message: 'Downloading file...',
            })
            this.$api.get(url).then(({ data }) => {
                window.open(data.url, '_blank')
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
    },
}
</script>
